import React from "react";
import PhoneInput from "react-phone-number-input";
import MainTemplate from "../templates/MainTemplate";
import withTracker from "../../blueberry/connect";
import { Helmet } from "react-helmet";
import validate from "../utils/validator";
import Blueberry from "../../blueberry/index";
import ErrorMessage from "../components/ErrorMessage/index";
import { ControlExtended } from "../components/LeadForm/style";
import { Section, PageContent, Heading, Row } from "../sdk/Layout";
import { Wrapper } from "./style";
import { Button, Buttons } from "../sdk/Button";
import { Control, Field, FieldBody } from "../sdk/Form";
import { config } from "../../config";

// import Blueberry from "../../blueberry/index";
// import withTracker from "../../blueberry/connect";

var captchaClienIdContact = null;
function onRecaptchaLoadCallbackContact() {
    captchaClienIdContact = grecaptcha.render("inline-badge", {
        sitekey: "6LemZq4ZAAAAAN5R0IFmny9jex0HBbz7jbuBFm4A",
        badge: "inline",
        size: "invisible",
    });
}

class ContactUs extends MainTemplate {
    constructor(props) {
        super(props);

        let formControls = {
            firstName: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
            lastName: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
            contactPhoneNumber: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true
                }
            },
            email: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                    isEmail: true,
                },
            },
            comments: {
                value: "",
                valid: false,
                touched: false,
                validationRules: {
                    minLength: 3,
                    isRequired: true,
                },
            },
        };

        this.state = {
            formControls,
            isUpdating: false,
        };
    }
    componentDidMount() {
        if (typeof window !== "undefined")
            window.onRecaptchaLoadCallbackContact =
                onRecaptchaLoadCallbackContact;
    }

    validateForm(formElement) {
        if (formElement.valid && formElement.touched) return "is-valid";
        if (!formElement.valid && formElement.touched) return "is-invalid";
        return "";
    }

    setValue = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedControls = {
            ...this.state.formControls,
        };
        const updatedFormElement = {
            ...updatedControls[name],
        };

        updatedFormElement.value = value;
        updatedFormElement.valid = validate(
            value,
            updatedFormElement.validationRules
        );
        updatedFormElement.touched = true;

        updatedControls[name] = updatedFormElement;

        this.setState({
            formControls: updatedControls,
        });
    };

    getFormKey = () => {
        let form_key = "7eYXXzaZgxno8ESmz";

        if (config.IS_NORITEX) {
            switch (this.props.match.params.lang) {
                case "en":
                    form_key = "wBe77wah2FT5pi7gG";
                    break;
                case "pt":
                    form_key = "thTs7x2kTZMTdd7QY";
                    break;
                default:
                    form_key = "7eYXXzaZgxno8ESmz";
                    break;
            }
        } else {
            switch (this.props.match.params.lang) {
                case "en":
                    form_key = "wBe77wah2FT5pi7gG_m";
                    break;
                case "pt":
                    form_key = "thTs7x2kTZMTdd7QY_m";
                    break;
                default:
                    form_key = "7eYXXzaZgxno8ESmz_m";
                    break;
            }
        }

        return form_key;
    };

    submitForm = (e) => {
        e.preventDefault();
        const { formControls } = this.state;
        let hasErrors = false;
        let formData = {};
        const updatedFormElement = { ...formControls };

        for (let formElementId in formControls) {
            if (!formControls[formElementId].valid) {
                hasErrors = true;
                updatedFormElement[formElementId].touched = true;
            }
            formData[formElementId] = formControls[formElementId].value;
            // formData += `${formElementId}=${formControls[formElementId].value}&`;
        }

        if (hasErrors) {
            this.setState({ formControls: updatedFormElement });
            return;
        }

        const form_key = this.getFormKey();

        this.setState({ isUpdating: true });

        const recordData = () => {
            Blueberry.formInsert({ fields: formData, form_key })
                .then((resp) => {
                    this.setState({ isUpdating: false, success: true });
                })
                .catch((err) => {
                    this.setState({ isUpdating: false, success: true });
                });
        };

        grecaptcha.ready(() => {
            grecaptcha
                .execute(captchaClienIdContact, {
                    action: "submit",
                })
                .then((token) => {
                    formData.captch_token = token;
                    recordData();
                })
                .catch((err) => {
                    this.setState({ isUpdating: false, hasError: true });
                    console.log(err);
                });
        });
    };

    renderBody() {
        const { firstName, lastName, email, contactPhoneNumber, comments } =
            this.state.formControls;
        const { isUpdating, hasError } = this.state;
        const { lang } = this.props.match.params;
        let data = this.props.data
            ? this.props.data
            : {
                  content: "",
                  title: this._t("conctactUs"),
                  seo: {
                      title: this._t("conctactUs") + " - Noritex",
                      page: this._t("contactUsDes"),
                  },
              };
        const { pathname } = this.props.location;
        const title = data.seo.title != "" ? data.seo.title : data.title;
        const description =
            data.seo.description != ""
                ? data.seo.description
                : this.removeHtml(data.content);

        const baseUrl = this.stripLangDir(lang, pathname);
        const enUrl = this.addLangDir("en", pathname, baseUrl);
        const esUrl = this.addLangDir("es", pathname, baseUrl);

        return (
            <Section>
                <Helmet>
                    <title>{title}</title>
                    <script
                        type="text/javascript"
                        src="https://www.google.com/recaptcha/api.js?render=explicit&onload=onRecaptchaLoadCallbackContact"
                    />
                    <meta name="description" content={description} />
                    <meta
                        name="keywords"
                        content="noritex,wholesale,decoracion,mayorista,hogar,navidad,textil,religioso"
                    />
                    {/*Alternate*/}
                    <link rel="alternate" hreflang="es" href={`${esUrl}`} />
                    <link rel="alternate" hreflang="en" href={`${enUrl}`} />
                    {/*Facebook*/}
                    <meta property="og:site_name" content="Noritex" />
                    <meta
                        property="og:url"
                        content={`https://www.noritex.com${pathname}`}
                    />
                    <meta property="og:title" content={title} />
                    <meta property="og:type" content="website" />
                    <meta property="og:description" content={description} />
                    {/*Twitter*/}
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />

                    <script type="application/ld+json">
                        {`
	                        {
	                          "@context": "https://schema.org",
	                          "@type": "Organization",
	                          "url": "https://www.noritex.com",
	                          "name": "Noritex",
	                          "contactPoint": {
	                            "@type": "ContactPoint",
	                            "telephone": "+507-300-3003",
	                            "contactType": "Customer service"
	                          }
	                        }
                    	`}
                    </script>
                </Helmet>
                <Wrapper>
                    <Heading isCentered>
                        <h3>{data.title}</h3>
                    </Heading>

                    <div>
                        <form>
                            <input
                                type="hidden"
                                value={this.getFormKey()}
                                name="form_key"
                            />
                            <input
                                type="hidden"
                                value="https://www.noritex.com/es/pages/gracias-por-registrarse/"
                                name="redirect"
                            />
                            {this.state.success && (
                                <ErrorMessage
                                    sucess={this.state.success}
                                    errorMessage={this._t("successContact")}
                                />
                            )}
                            {this.state.hasError && (
                                <ErrorMessage
                                    errorMessage={this._t("errorContact")}
                                />
                            )}

                            <Field>
                                <FieldBody>
                                    <Field>
                                        <Control isExpanded>
                                            <label htmlFor="customer_password">
                                                {this._t("firstName")}
                                            </label>
                                            <input
                                                className={`${this.validateForm(
                                                    firstName
                                                )}`}
                                                name="firstName"
                                                value={firstName.value}
                                                onChange={this.setValue}
                                                type="text"
                                                autoComplete="off"
                                            />
                                        </Control>
                                    </Field>
                                    <Field>
                                        <Control isExpanded>
                                            <label htmlFor="customer_password">
                                                {this._t("lastName")}
                                            </label>
                                            <input
                                                className={`${this.validateForm(
                                                    lastName
                                                )}`}
                                                name="lastName"
                                                value={lastName.value}
                                                onChange={this.setValue}
                                                type="text"
                                                autoComplete="off"
                                            />
                                        </Control>
                                    </Field>
                                </FieldBody>
                            </Field>

                            <Field>
                                <FieldBody>
                                    <Field>
                                        <Control isExpanded>
                                            <label htmlFor="customer_password">
                                                Email
                                            </label>
                                            <input
                                                className={`${this.validateForm(email)}`}
                                                name="email"
                                                value={email.value}
                                                onChange={this.setValue}
                                                type="text"
                                                autoComplete="off"
                                            />
                                        </Control>
                                    </Field>
                                    <Field>
                                        <ControlExtended isExpanded>
                                            <label htmlFor="contactPhoneNumber">
                                                {this._t("contactPhoneNumber")}
                                            </label>
                                            <PhoneInput
                                                className={`${this.validateForm(contactPhoneNumber)}`}
                                                name="contactPhoneNumber"
                                                autoComplete="off"
                                                aria-label="Phone Number"
                                                value={contactPhoneNumber.value}
                                                onChange={(val) => this.setValue({
                                                    target: {
                                                        name: "contactPhoneNumber",
                                                        value: val ?? "",
                                                    },
                                                })}
                                                placeholder={this._t("contactPhoneNumber")}
                                            />
										</ControlExtended>
                                    </Field>
                                </FieldBody>
                            </Field>

                            <Field>
                                <Control isExpanded>
                                    <label htmlFor="customer_password">
                                        {this._t("comments")}
                                    </label>
                                    <textarea
                                        className={`${this.validateForm(
                                            comments
                                        )}`}
                                        name="comments"
                                        value={comments.value}
                                        onChange={this.setValue}
                                        type="text"
                                        autoComplete="off"
                                    />
                                </Control>
                            </Field>
                            <Buttons isCentered>
                                <Button
                                    loading={isUpdating}
                                    type="submit"
                                    primary
                                    onClick={this.submitForm}
                                >
                                    {this._t("conctactUs")}
                                </Button>
                            </Buttons>
                            <div id="inline-badge"></div>
                        </form>
                    </div>
                </Wrapper>
            </Section>
        );
    }
}

export default withTracker()(ContactUs);

// export default withSSR(Page);
